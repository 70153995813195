<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <ul class="rating-bar" :class="{noWidth: is10}">
      <li :class="square(1)" />
      <li :class="square(2)" />
      <li :class="square(3)" />
      <li :class="square(4)" />
      <li :class="square(5)" />
      <li v-if="is10" :class="square(6)" />
      <li v-if="is10" :class="square(7)" />
      <li v-if="is10" :class="square(8)" />
      <li v-if="is10" :class="square(9)" />
      <li v-if="is10" :class="square(10)" />
    </ul>
    <span class="numbers">{{ value }}/{{ is10 ? '10' : '5' }}</span>
  </div>
</template>
<style scoped>
  .noWidth {
    width: auto !important;
  }
</style>
<script>
export default {
  props: ['value', 'label', 'is10'],
  methods: {
    square (val) {
      if (this.value >= val) {
        return 'full'
      }
      if (this.value > val - 1) {
        return 'half'
      }
      return null
    }
  }
}
</script>